import React, { useCallback } from 'react';
import { BigidRichTextEditor, BigidRichTextEditorProps } from '@bigid-ui/rich-text-editor';

import { fontSizeList, toolbarButtonsConfig } from './config';

type RichTextEditorPropsType = { toolbarVariant?: keyof typeof toolbarButtonsConfig } & BigidRichTextEditorProps;

/**
 *
 * @example controlled usage
 *
 * <RichTextEditor
 *  isControlled // default true
 *  onBlur // callback
 * />
 *
 *  @example uncontrolled usage
 *
 * <RichTextEditor
 *  isControlled={false} // default true
 *  onChange // callback
 *  // or
 *  onBlur // callback
 * />
 *
 * Add muiOverrides={{ disableEnforceFocus: true }} to BigidDialog for correct work in dialog
 */

export const RichTextEditor = ({
  toolbarVariant = 'default',
  isControlled = true,
  onBlur,
  onChange,
  ...props
}: RichTextEditorPropsType) => {
  const handleBlur = useCallback(
    (html: string) => {
      if (html === '' || html === '<p><br></p>') {
        onBlur?.('');
      } else if (html.slice(0, 3) === '<p>' && html.slice(-4) === '</p>') {
        onBlur?.(html);
      } else {
        onBlur?.(`<p>${html}</p>`);
      }
    },
    [onBlur],
  );

  const handleChange = useCallback(
    (html: string) => {
      if (html === '' || html === '<p><br></p>') {
        onChange?.('');
      } else if (html.slice(0, 3) === '<p>' && html.slice(-4) === '</p>') {
        onChange?.(html);
      } else {
        onChange?.(`<p>${html}</p>`);
      }
    },
    [onChange],
  );

  return (
    <BigidRichTextEditor
      forceOpenLinkInNewTab
      isControlled={isControlled}
      fontSizeList={fontSizeList}
      {...props}
      onChange={onChange ? handleChange : undefined}
      onBlur={onBlur ? handleBlur : undefined}
      buttons={toolbarButtonsConfig[toolbarVariant]}
      safeMode
    />
  );
};
