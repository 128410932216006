import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  ColumnChooserGroup,
  ToolbarActionType,
  ActionData,
  entityEventsEmitter,
  EntityEvents,
  ToolbarAction,
  BigidAdvancedToolbarFilter,
  BigidPaper,
} from '@bigid-ui/components';
import { BigidGridWithToolbar } from '@bigid-ui/grid';

import { GridRowType, ProcessingStageType } from '../../../types';
import { formExtendDateRequestParams, SnackbarUtils } from '../../../utils';
import { RequestManagerGridContainer } from '../useRequestManagerGrid';
import { RequestManagerStateContainer } from '../useRequestManagerState';
import { ReactComponent as RequestEmptyState } from '../../../assets/illustrations/emptyState/Requests_Empty_State.svg';
import { GridNoData } from '../../../components/GridNoData/GridNoData';
import { extendDueDate, postVerificationStatus } from '../../../services/request';
import { chunk, find, noop } from 'lodash';
import { RequestManagerGridControls } from '../RequestManagerGridControls';
import { RequestManagerViewsContainer } from '../useRequestManagerViews';
import { Loader } from '../../../components';
import { useTheme } from '@mui/styles';

export const RequestManagerGrid = () => {
  const {
    onRowClickHandler,
    columns,
    allUserRequestAttributesInfo,
    allUserRequestPreferencesInfo,
    onGridStateChanged,
    defaultSorting,
    fetchRequests,
    requestsFilters,
    advancedToolbarSelectedOptionsConfig,
    advancedToolbarFilters,
    loadingGridFilters,
    onRequestsFiltersChange,
    onFilterReload,
    toolbarKey,
  } = RequestManagerGridContainer.useContainer();
  const { requestManagerState } = RequestManagerStateContainer.useContainer();
  const { viewLoading, currentView, isToolbarBusy } = RequestManagerViewsContainer.useContainer();
  const [gridKey, setGridKey] = useState(0);

  const theme = useTheme();

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'Extend Time',
      type: ToolbarActionType.SECONDARY,
      execute: async ({ selectedRowIds }: ActionData) => {
        if (!selectedRowIds) {
          return Promise.resolve({});
        }

        const {
          requests = [],
          extendedBefore = [],
          nonExtensible = [],
        } = await extendDueDate(formExtendDateRequestParams(selectedRowIds));
        const requestsTotal = requests.length + extendedBefore.length + nonExtensible.length;
        const renderCount = (items: string[] | GridRowType[]) => `${items.length} out of ${requestsTotal}`;

        entityEventsEmitter.emit(EntityEvents.UPDATE, requests);
        SnackbarUtils.toast(
          <div>
            {!!requests.length && <div>{`${renderCount(requests)} extended successfully`}</div>}

            {!!extendedBefore.length && (
              <div>{`${renderCount(extendedBefore)} ${
                extendedBefore.length > 1 ? 'were' : 'was'
              } already extended`}</div>
            )}

            {!!nonExtensible?.length && <div>{`${renderCount(nonExtensible)} not eligible for extension`}</div>}
          </div>,
        );

        return Promise.resolve({ shouldGridReload: false, shouldClearSelection: true });
      },
      show: ({ selectedRowIds }: ActionData) =>
        !!selectedRowIds?.length && !!requestManagerState?.actions?.EXTEND_REQUEST,
      disable: ({ selectedRows }: ActionData) => !!find(selectedRows, 'closed'),
    },
    {
      label: 'Verify',
      type: ToolbarActionType.SECONDARY,
      execute: async ({ selectedRows }: ActionData) => {
        if (!selectedRows) {
          return Promise.resolve({});
        }

        const verifyRequests = selectedRows.filter(sr => sr.processingStage === ProcessingStageType.VERIFY);
        for (const currentChunk of chunk(verifyRequests, 5)) {
          await Promise.all(currentChunk.map(vr => postVerificationStatus(vr.id, true)));
        }

        SnackbarUtils.toast(<div>{`${verifyRequests.length} requests verified successfully`}</div>);

        return Promise.resolve({ shouldGridReload: true, shouldClearSelection: true });
      },
      show: ({ selectedRows }: ActionData) =>
        !!selectedRows?.filter(sr => sr.processingStage === ProcessingStageType.VERIFY)?.length &&
        !!requestManagerState?.actions?.VERIFY_STAGE_APPROVE,
      disable: ({ selectedRows }: ActionData) => !!find(selectedRows, 'closed'),
    },
  ];

  const groupsForColumnChooser = useMemo<ColumnChooserGroup[] | undefined>(
    () =>
      allUserRequestPreferencesInfo?.length
        ? [
            {
              name: 'requestPreferencesColumns',
              label: 'Preferences Values',
              columnNames: allUserRequestPreferencesInfo?.map(prefInfo => `preference-${prefInfo.name}`) || [],
            },
          ]
        : undefined,
    [allUserRequestPreferencesInfo],
  );
  // force remount grid
  useEffect(() => {
    if (!isToolbarBusy && columns.length > 0) {
      setGridKey(prev => prev + 1);
    }
  }, [requestsFilters, isToolbarBusy, columns.length]);

  // if change columns number in runtime Grid components throws error
  if (!allUserRequestAttributesInfo || !allUserRequestPreferencesInfo) {
    return null;
  }

  if (viewLoading || loadingGridFilters) return <Loader />;

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="auto">
      <BigidPaper>
        <Box mt={2} mb={2} ml={2} maxWidth="calc(100% - 185px)">
          {!loadingGridFilters && currentView ? (
            <BigidAdvancedToolbarFilter
              filters={advancedToolbarFilters}
              onFiltersChange={onRequestsFiltersChange}
              selectedOptionsConfig={advancedToolbarSelectedOptionsConfig}
              onInitialize={noop}
              onFilterReload={onFilterReload}
              key={`${currentView?.id} ${toolbarKey}`}
            />
          ) : (
            <></>
          )}
        </Box>
        <Box
          borderTop={`1px solid ${theme.vars.palette.bigid?.gray300}`}
          overflow="scroll"
          display="flex"
          height="100%"
        >
          <BigidGridWithToolbar
            onGridStateChange={onGridStateChanged}
            columns={columns}
            entityName={{
              none: 'Requests',
              single: 'Request selected',
              plural: 'Requests selected',
              all: 'Requests selected',
            }}
            fetchData={fetchRequests}
            onRowClick={onRowClickHandler}
            toolbarActions={toolbarActions}
            isRowDisabled={(row: GridRowType) => !row.inLimit}
            defaultSorting={defaultSorting}
            key={gridKey}
            showSelectAllColumnChooser
            groupsForColumnChooser={groupsForColumnChooser}
            foreignElement={<RequestManagerGridControls />}
            noDataContent={<GridNoData description={'There are no requests '} illustration={<RequestEmptyState />} />}
          />
        </Box>
      </BigidPaper>
    </Box>
  );
};
